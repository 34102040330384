let removeScrollFromBody = (conditionValue: boolean) => {
  let body = document.querySelector("body");

  if (conditionValue) {
    body?.classList.add("no-scroll");
  } else {
    body?.classList.remove("no-scroll");
  }
};

export default removeScrollFromBody;