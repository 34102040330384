import "../base.scss";
import "./styles.scss";
import Choices from "choices.js";

//Função para a máscara do número
let inputMask = () => {
  const phoneInput = document.querySelector('[data-js="phone"]')
  phoneInput?.addEventListener('input', handleInput, false)
  
  function handleInput (e:any) {
    e.target.value = phoneMask(e.target.value)
  }
  
  function phoneMask (phone:any) {
    return phone.replace(/\D/g, '')
      .replace(/^(\d)/, '($1')
      .replace(/^(\(\d{2})(\d)/, '$1) $2')
      .replace(/(\d{5})(\d{1,5})/, '$1-$2')
      .replace(/(-\d{4})\d+?$/, '$1');
  }
}

inputMask();


//Função para a lista de opções utilizando a ferramenta Choices
const FormSelect = function () {
  let elements = Array.from(document.querySelectorAll(".select"));

  if (elements) {
    elements.forEach(function (item) {
      let choices = () => new Choices(item, {
        searchEnabled: false,
        itemSelectText: "",
      });

      choices();
    });
  }
};

FormSelect();
