import "../base.scss";
import "./styles.scss";
import "swiper/scss";
import "swiper/scss/navigation";
import Swiper, { Navigation } from "swiper";

const gallerySwiper = () => {
  new Swiper(".gallery .swiper", {
    modules: [Navigation],
    spaceBetween: 0,
    loop: true,
    navigation: {
      nextEl: ".swiper-next",
      prevEl: ".swiper-prev",
    },
    breakpoints: {
      1330: {
        slidesPerView: 1.34,
      },

      768: {
        slidesPerView: 1.34,
      },
      
      375: {
        slidesPerView: 1.18,
      },
    },
  });
};

gallerySwiper();

const stopVideo = () => {
  
  let swiperSlides = document.querySelectorAll('.swiper-slide')

  swiperSlides.forEach((element) => {
    let video = element.querySelector('iframe')

    if (element.classList.contains('swiper-slide-active') == false) {
      if(element.children[0] == video) {
        video.contentWindow?.postMessage(JSON.stringify({
          event: 'command', func: 'stopVideo'
        }), '*');
      }
    }
  })
}

const videoController = () => {
  const swiperNext = document.querySelectorAll('.swiper-next');
  const swiperPrev = document.querySelectorAll('.swiper-prev');

  swiperNext.forEach((element) => {
    element.addEventListener('click', stopVideo);
  });

  swiperPrev.forEach((element) => {
    element.addEventListener('click', stopVideo);
  });
}

videoController();

