import removeScrollFromBody from '../../helpers/removeScrollFromBody';

import '../../components/reset.scss'
import './styles.scss'

/*
  - O argumento "target" é o valor apendado a "data-modal" presente em cada modal, que eu desejo apontar como meu alvo.
  Por exemplo, eu possuo um modal usado em um Webdoor; portanto, é necessário aplicar:

  -> "data-modal-webdoor-target" nos botões que abrirão os modais;
  -> "data-modal-webdoor" nos modals correspondentes;

  Logo, a função Modal deve ser chamada, com o nome do componente a ser associada:

  Modal("webdoor");
*/
let Modal = (target: string | undefined) => {
  let modalBtns = Array.from(document.querySelectorAll(`[data-modal-${target}-target]`));

  if(modalBtns){
    modalBtns.forEach((modalBtn, index: number | string) => {
      let targetModal = document.querySelector(`[data-modal-${target}="${index}"]`);
      let modalIsActive = false as Boolean;

      if(modalIsActive){
        targetModal?.classList.add('active');
      }

      let modalManager = (e: Event) => {
        e.preventDefault();

        if(!targetModal){
          // Inverte o valor da flag (de false para true), para que o modal seja exibida assim que criado.
          modalIsActive = !modalIsActive;

          let createModal = () => {
            let main = document.body.querySelector('main') as HTMLElement;
            let iframeSrc = modalBtn.getAttribute('data-iframe-src');
            let modalToBeCreated = document.createElement('div');
            modalToBeCreated.setAttribute('class', `modal ${modalIsActive ? "active" : ""}`);
            modalToBeCreated.setAttribute(`data-modal-${target}`, `${index}`);

            switch (target){
              case 'webdoor':
                let webdoorModalHTML = `
                  <div class="modal-content">
                    <button class="modal-close-btn"></button>
    
                    <figure>
                      <iframe class="modal-iframe" width="100%" height="507" src=${iframeSrc} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </figure>
                  </div>
                `

                modalToBeCreated.innerHTML = webdoorModalHTML;
                main.insertBefore(modalToBeCreated, main.children[0]!);   

                break;

              case 'header':
                let langIsEn = document.querySelector('html')?.getAttribute('lang') === "en";

                let headerModalHTML = `
                  <div class="modal-content">
                    <button class="modal-close-btn"></button>
            
                    <form class="input-field" action="${langIsEn ? '/en' : ''}/search/">
                      <label class="input-field-label" for="search">${langIsEn ? 'Search' : 'Busca'}</label>
            
                      <div class="input-field-capture">
                        <input name="query" class="input-field-capture-input" type="text" placeholder="${langIsEn ? 'Type here' : 'Digite aqui'}" />
                        <button class="input-field-capture-button" type="submit"></button>
                      </div>
                    </form>
                  </div>
                `

                modalToBeCreated.innerHTML = headerModalHTML;
                main.insertBefore(modalToBeCreated, main.children[0]!);   

                break;

              case 'galleryImage':
                let galleryImageItem = modalBtn.closest('.gallery-image-item');
                let galleryImageItemImage = galleryImageItem?.querySelector('figure img');
                let galleryImageItemCaption = galleryImageItem?.querySelector('figure figcaption');

                let galleryImageModalHTML = `
                  <div class="modal-content">
                    <button class="modal-close-btn"></button>
                    <p class="modal-content-text">${(galleryImageItemCaption as HTMLElement).innerText}</p>

                    <figure>
                      <img class="modal-content-image" src="${galleryImageItemImage?.getAttribute('src')}" width="904" height="508" alt="" />
                    </figure>
                  </div>
                `

                modalToBeCreated.innerHTML = galleryImageModalHTML;
                main.insertBefore(modalToBeCreated, main.children[0]!);   

                break;

              case 'galleryVideo':
                let galleryVideoItem = modalBtn.closest('.gallery-video-item');
                let galleryVideoItemIframe = galleryVideoItem?.querySelector('figure iframe');

                let galleryVideoModalHTML = `
                  <div class="modal-content">
                    <button class="modal-close-btn"></button>

                    <figure>
                      <iframe class="modal-iframe" width="100%" height="507" src="${galleryVideoItemIframe?.getAttribute('src')}" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </figure>
                  </div>
                `

                modalToBeCreated.innerHTML = galleryVideoModalHTML;
                main.insertBefore(modalToBeCreated, main.children[0]!);   

                break;

              case 'history':
                let historyVideoModalHTML = `
                  <div class="modal-content">
                    <button class="modal-close-btn"></button>

                    <figure>
                      <iframe class="modal-iframe" width="100%" height="507" src="${iframeSrc}" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </figure>
                  </div>
                `

                modalToBeCreated.innerHTML = historyVideoModalHTML;
                main.insertBefore(modalToBeCreated, main.children[0]!);   

                break;  

              default:
                return false;
            }
          }

          createModal();
        }

        let openModal = () => {
          removeScrollFromBody(true);
        }

        let closeModal = () => {
          /**
           * Somente uma modal existirá, por vez!
           * Como irei deletar a modal ao fechá-la, posso pegar pela classe diretamente e sem me preocupar com o pai.
           * */
          let closeBtn = document.querySelector('.modal-close-btn');

          if(closeBtn){
            closeBtn.addEventListener('click', (el) => {
              let modalParent = (el.target as HTMLInputElement).closest('.modal');
              modalParent?.remove();
              removeScrollFromBody(false);
            });

            modalIsActive = false;
          }

          let modal = document.querySelector('.modal');

          modal?.addEventListener('click', (el) => {
            if((el.target as HTMLElement).classList.contains('modal')){
              modal?.remove();
              removeScrollFromBody(false);
            }
          });
        }

        openModal();
        closeModal();
      }

      modalBtn.addEventListener('click', (element) => modalManager(element));
    });
  }
}

export default Modal;