import '../reset.scss';
import "../base.scss";
import "./styles.scss";
import '../BaseSlider/styles.scss';

import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';
import Swiper, { Navigation, Pagination } from 'swiper';

const BaseSliderSwiper = () => {
  new Swiper('.base-slider .swiper', {
    modules: [Navigation, Pagination],
    spaceBetween: 20,
    pagination: {
      el: '.base-slider .swiper .swiper-pagination',
      clickable: true,
      type: 'bullets',
    },
    navigation: {
      nextEl: '.swiper-next',
      prevEl: '.swiper-prev',
    },
    breakpoints: {
      1300: {
        slidesPerView: 4,
        spaceBetween: 20,
      },

      1024: {
        slidesPerView: 3,
        spaceBetween: 20,
      },

      768: {
        slidesPerView: 2,
        spaceBetween: 20,
      },

      425: {
        slidesPerView: 1,
        spaceBetween: 15,
      },

      375: {
        slidesPerView: 1,
        spaceBetween: 15,
      },
    },
  });
}

BaseSliderSwiper();

const charLimiter = () => {
  let swiperSlide = document.querySelectorAll('.swiper-slide');

  swiperSlide.forEach((element) => {
    let slideTitle = element.querySelector('h1');
    let slideDescription = element.querySelector('p');

    if (slideTitle?.innerText.length) {
      if (slideTitle.innerText.length > 70) {
        slideTitle.innerText = slideTitle.innerText.substring(0, 70) + "...";
      }
    }

    if (slideDescription?.innerText.length) {
      if (slideDescription.innerText.length > 175) {
        slideDescription.innerText = slideDescription.innerText.substring(0, 175) + "...";
      } 
    }
  })
}

charLimiter();
