import "../base.scss";
import "../../modules/MenuSwipe/styles.scss";

let nav = () => {
  let isOpen = false;

  let navigationDropdown = () => {
    let selects = Array.from(document.querySelectorAll('.navigation-dropdown'));

    selects.forEach((select) => {
      let selectedItem = select.querySelector('.navigation-dropdown-selected') as Element;
      // let selectItems = Array.from(select.querySelectorAll('.navigation-dropdown-list-item'));

      selectedItem.addEventListener('click', () => {
        isOpen = !isOpen;
  
        // Falta implementar o fechamento, ao clicar fora!
        if(isOpen === true){
          select.classList.add('open');
        }
  
        else {
          select.classList.remove('open');
        }
      });
  
      // selectItems.forEach((selectItem) => {
      //   selectItem.addEventListener('click', () => {
      //     // el.preventDefault();
  
      //     // console.log(el.target);
      //     // selectedItem.innerText = el.target.innerText;
      //     isOpen = !isOpen;
      //   });
      // })
    });
  }
  
  /**
   * Cria um select, a partir dos itens no menu de navegação secundário; então, aplica o choices.
   */
  let createSelectFromList = () => {
    let listWrapper = Array.from(document.querySelectorAll('.component-slider-wrapper'));
    let isMobile = window.matchMedia('(max-width: 1023px)').matches;
    let currentActiveMenuItem: any;
  
    listWrapper?.forEach(listWrapperItem => {
      let list = listWrapperItem.querySelector('.slider-menu');
      let listItems = Array.from((list as HTMLElement).querySelectorAll('.slider-menu-item-link'));
      currentActiveMenuItem = listWrapperItem.querySelector('.slider-menu-item-link.active');
      console.log(currentActiveMenuItem);
  
      if(isMobile === true || listItems.length > 4){
        (listWrapperItem as HTMLElement).style.opacity = "0";
        (listWrapperItem as HTMLElement).style.visibility = "hidden";
        (listWrapperItem as HTMLElement).style.display = "none";
  
        // Retorna um array de objetos, a partir do texto e href dos itens atuais no menu de navegação.
        let assembleArray = listItems.map((listItem:Element) => {
          let listItemText = listItem.innerHTML.trim() as string;
          let listItemURL = listItem.getAttribute('href');
  
          return (
            {
              text: listItemText,
              URL: listItemURL
            }
          );
        });
  
        // Monta o select, usando o 'assembleArray' para gerar os options.
        let assembleSelect = (e: any[]) => {
          let listValues = e.map((listValue) => {
            return (
              `<li class="navigation-dropdown-list-item">
                <a href=${listValue.URL} class="navigation-dropdown-list-item-link">${listValue.text}</a>
              </li>`
            );
          });
  
          let newDropdownWrapper = document.createElement('div');
          newDropdownWrapper.setAttribute('class', 'navigation-dropdown');
  
          let newDropdownSelected = document.createElement('span');
          newDropdownSelected.setAttribute('class', 'navigation-dropdown-selected');
 
          let newDropdownList = document.createElement('ul');
          newDropdownList.setAttribute('class', 'navigation-dropdown-list');
  
          let newDropdownListValues = `${listValues}`;
          newDropdownListValues = newDropdownListValues.replace(/,/g, '');

          newDropdownSelected.innerText = currentActiveMenuItem?.innerText.trim();

          // let returnValue = () => {
          //   if(currentActiveMenuItem === null){
          //     // @ts-ignore
          //     newDropdownSelected.innerText = newDropdownListValues[0].text;
          //     console.log(newDropdownListValues);
          //   }

          //   else {
          //     newDropdownSelected.innerText = currentActiveMenuItem?.innerText.trim();
          //   }
          // }

          // returnValue();
  
          newDropdownWrapper.appendChild(newDropdownSelected);
  
          newDropdownList.innerHTML = newDropdownListValues;
          newDropdownWrapper.appendChild(newDropdownList);

          listWrapperItem.after(newDropdownWrapper);

          let navDropdown = listWrapperItem.nextElementSibling;
          let navDropdownLinks = Array.from(navDropdown!.querySelectorAll('.navigation-dropdown-list-item-link'));

          navDropdownLinks.forEach((navDropdownLinksItem) => {
            navDropdownLinksItem.addEventListener('click', (e:any) => {
              newDropdownSelected.innerText = e.target.innerText;
              isOpen = !isOpen;
            });
          });

          navigationDropdown();
        };

        assembleSelect(assembleArray);

        // let select = () => {
        //   let flag = false as boolean;
        //   let selectElements = Array.from(document.querySelectorAll(".slider-menu-select"));
    
        //   if (flag === false) {
        //     flag = true;
    
        //     if (selectElements) {
        //       selectElements.forEach((item) => {
        //         let selectOptions = Array.from(item.querySelectorAll('option'));
  
        //         // Extrai o data-url de cada option, salva os mesmos em um array.
        //         // let datasetValues = selectOptions.map((selectOption) => {
        //         //   return selectOption.dataset.url
        //         // });
  
        //         // let choices = () => new Choices(item, {
        //         //   searchEnabled: false,
        //         //   itemSelectText: "",
        //         //   shouldSort: false,
  
        //         //   callbackOnInit: function(this){
        //         //     console.log(this);
        //         //     let selectLinks = document.querySelectorAll('.choices-select-link');
  
        //         //     selectLinks.forEach(selectLink => {
        //         //       let selectLinksParent = selectLink.parentElement;
      
        //         //       selectLinksParent.addEventListener("click", (e) => {
        //         //         e.stopPropagation()
        //         //       })
        //         //     })
        //         //   },
  
        //         //   callbackOnCreateTemplates: function(template){
        //         //     return {
        //         //       choice: ({ classNames }:any, data: any) => {
        //         //         /* A partir do array 'datasetValues', pego o valor correspondente usando o data.id do choices. Subtraio 1, pois arrays começam em 0 e o data.id começa em 1. */
        //         //         return template(`
        //         //           <div class="${classNames.item} ${classNames.itemChoice} ${data.disabled ? classNames.itemDisabled : classNames.itemSelectable}" data-choice ${
        //         //             data.disabled
        //         //               ? 'data-choice-disabled aria-disabled="true"'
        //         //               : 'data-choice-selectable'
        //         //           } data-id="${data.id}" data-value="${data.value}" ${
        //         //             data.groupId > 0 ? 'role="treeitem"' : 'role="option"'
        //         //           }>
        //         //             <a class="choices-select-link" href="${datasetValues[data.id - 1]}">${data.label}</a>
        //         //           </div>
        //         //         `);
        //         //       },
        //         //     }
        //         //   }
        //         // });
  
        //         // choices();
        //       });
        //     }
        //   }
        // };
  
        // select();
      }
    });
  };

  createSelectFromList();
}

nav();