import '../reset.scss';
import "../base.scss";
import "./styles.scss";

const charLimiter = () => {
    let newsCard = document.querySelectorAll('.news-group-card');

    newsCard.forEach((element) => {
        let cardTitle = element.querySelector('h1');
        let cardDescription = element.querySelector('p');

        if (cardTitle?.innerText.length) {
            if (cardTitle.innerText.length > 70) {
                cardTitle.innerText = cardTitle.innerText.substring(0, 70) + "...";
            }
        }

        if (cardDescription?.innerText.length) {
            if (cardDescription.innerText.length > 175) {
                cardDescription.innerText = cardDescription.innerText.substring(0, 175) + "...";
            } 
        }
    })
}

charLimiter();