import "../base.scss";
import "./styles.scss";

import getSiblings from '../../helpers/getSiblings';

let accordion = () => {
  let accordionArea = Array.from(document.querySelectorAll('.accordion'));
  
  if(accordionArea){
    accordionArea.forEach((accordionAreaItem) => {
      let accordionTabs = accordionAreaItem.querySelectorAll('.accordion-item');
    
      accordionTabs.forEach((accordionTabsItem) => {
        let accordionTabsItemHeader = accordionTabsItem.querySelector('.accordion-item-top');

        accordionTabsItemHeader?.addEventListener('click', (e) => {
          e.preventDefault();
          e.stopPropagation();

          let itemSiblings = getSiblings(accordionTabsItem as HTMLElement);

          itemSiblings.forEach((itemSibling:any) => {
            itemSibling.classList.remove('active');
          });

          if(accordionTabsItem.classList.contains('active')){
            accordionTabsItem.classList.remove('active');
          }

          else {
            accordionTabsItem.classList.add('active');
          }
        });
      });
    });
  }
}

accordion();

export default accordion;
