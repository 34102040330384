import "../base.scss";
import "./styles.scss";

import 'swiper/css';
import 'swiper/scss/pagination';
import 'swiper/scss/effect-fade';

import Swiper, { Pagination, EffectFade } from 'swiper';
import Modal from '../../modules/Modal/main';

const webdoorSwiper = () => {
  let webdoor = document.querySelector('.webdoor');

  new Swiper(".webdoor .swiper", {
    modules: [Pagination, EffectFade],
    effect: 'fade',
    fadeEffect: {
      crossFade: true,
    },

    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true,
    },
  });
  
  window.addEventListener('load', () => {
    setTimeout(() => {
      webdoor?.classList.remove('hidden');
    }, 500);
  });
}

webdoorSwiper();
Modal("webdoor");