import '../reset.scss';
import "../base.scss";
import "./styles.scss";
import '../HighlightSlider/styles.scss';

import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';
import Swiper, { Navigation, Pagination } from 'swiper';

let sliders = document.querySelectorAll('.highlight-slider-wrapper .sliders');

const HighlightSliderSwiper = () => {
  new Swiper('.sliders .swiper', {
    modules: [Navigation, Pagination],
    loop: false,
    spaceBetween: 0,
    watchOverflow: true,
    pagination: {
      el: '.sliders .swiper .swiper-pagination',
      clickable: true,
      type: 'bullets',
    },
    navigation: {
      nextEl: '.swiper-next',
      prevEl: '.swiper-prev',
    },
    breakpoints: {
      1330: {
        slidesPerView: 3,
        spaceBetween: 0,
      },

      768: {
        slidesPerView: 2.6,
        spaceBetween: 0,
      },

      375: {
        slidesPerView: 1.2,
        spaceBetween: 0,
      },
    },
  });
}

const hideButton = () => {
  sliders.forEach((element: any) => {
    const prev = element.querySelector('.swiper-prev') as HTMLElement;
    const next = element.querySelector('.swiper-next') as HTMLElement;

    if(prev.hasAttribute('disabled') && next.hasAttribute('disabled')) {
      prev.style.display = 'none';
      next.style.display = 'none';
    }
  })
}

HighlightSliderSwiper();
hideButton();


