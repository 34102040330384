import Modal from "../../modules/Modal/main";
import removeScrollFromBody from "../../helpers/removeScrollFromBody";
import getSiblings from "../../helpers/getSiblings";

import "../base.scss";
import "./styles.scss";

let Header = () => {
  let header = document.querySelector('#header') as HTMLElement; // inferência de tipos garante (para o código) que não haverá valor null!

  if (header){
    let menuMobileBtn = header.querySelector('.hamburger') as HTMLButtonElement;
    let isMobile = window.matchMedia('(max-width: 1023px)').matches;

    if(isMobile){
      let isOpen = false;

      let menuMobile = () => {
        let menuHandler = (e: Event) => {
          e.preventDefault();

          let eventTarget = e.target as HTMLElement;
          isOpen = !isOpen;
  
          if(isOpen === true){
            header.classList.add('active');
            eventTarget.classList.add('is-active');
            removeScrollFromBody(isOpen);
          }
  
          else {
            header.classList.remove('active');
            eventTarget.classList.remove('is-active');
            removeScrollFromBody(isOpen);
          }
        };

        let topListHandler = () => {
          if(isOpen === false) {
            let headerBottomWrapper = document.querySelector('.header-bottom-wrapper') as HTMLElement;
            let topList = header.querySelector('.header-top-list') as HTMLElement;

            /**
              * Inserir o topList ao interior do headerBottomWrapper quando isApended for false;
              * Quando isApender é true, remover o topList próximo ao dropdown.
              */
            let appendCloneToMenu = () => {
              let isApended = false;

              if(isApended === false){
                headerBottomWrapper?.appendChild(topList);
                isApended = !isApended;
              }
            }

            appendCloneToMenu();
          }
        }

        let dropdownHandler = () => {
          let dropdownItensBtn = Array.from(document.querySelectorAll('.header-bottom-item:not(.search)'));
          let dropdownSearch = header.querySelector('.header-bottom-item.search') as HTMLElement;
          let isDropdownOpen = false; // Variável de controle, para definir quando o dropdown está aberto ou fechado.

          dropdownItensBtn.forEach((dropdownItemBtn) => {
            dropdownItemBtn.addEventListener('click', (el:any) => {
              let elementClicked = el.target; // retorna a âncora.
              let elementClickedParent = elementClicked.parentElement;
              let dropdown = elementClicked.nextElementSibling as HTMLElement; // retorna a lista de dropdown.
              let elementClickedSiblings = getSiblings(elementClickedParent as HTMLElement); // retorna os siblings do elemento clicado.

              if(dropdown){
                el.preventDefault(); // Aplica o 'preventDefault' ao ponteiro responsável pelo evento, não ao elemento clicado em si.
                isDropdownOpen = true;

                if(isDropdownOpen === true){
                  if(elementClickedParent.classList.contains('active')){
                    elementClickedParent.classList.remove('active');
                    dropdownSearch.classList.remove('hide');
                  }

                  else {
                    elementClickedParent.classList.add('active');
                    dropdownSearch.classList.add('hide');
                  }

                  let checkIfThereAreActiveItens = () => {
                    elementClickedSiblings.forEach((sibling:any) => {
                      if(sibling.classList.contains('active')){
                        sibling.classList.remove('active');
                      }
                    })
                  }

                  checkIfThereAreActiveItens();
                }

                else {
                  dropdownSearch.classList.remove('hide');
                  elementClickedParent.classList.remove('active');
                }
              }
            });
          });
        }
  
        menuMobileBtn.addEventListener('click', (e) => menuHandler(e));
        topListHandler();
        dropdownHandler();
      }
  
      menuMobile();
    }

    else {
      let headerTop = document.querySelector(".header-top") as HTMLElement;
      let topList = header.querySelector('.header-top-list') as HTMLElement;

      headerTop?.appendChild(topList);
    }  
  }
}

let toggleSwitch = () => {
  let multilingual = document.querySelector(".header-top-list-item-multilingual");
  let multilingualOptions = multilingual?.querySelectorAll('.header-top-list-item-multilingual-option');
  const switchPT = multilingual?.querySelector('.pt');
  const switchEN = multilingual?.querySelector('.en');

  if(window.location.href.indexOf('/en/') != -1) {
    switchEN?.classList.add('active');
  }
  else {
    switchPT?.classList.add('active')
  }

  multilingualOptions?.forEach((multilingualOptionsItem) => {
    multilingualOptionsItem.addEventListener('click', (e) => {
      let target = e.target as HTMLElement;
      let optionSiblings = getSiblings(target);

      optionSiblings.forEach((optionSibling:any) => {
        if(!multilingualOptionsItem.classList.contains('active')){
          target.classList.add('active');
          optionSibling.classList.remove('active');
        }
      });
    });
  });
}

window.addEventListener('resize', Header);

Header();
toggleSwitch();
Modal("header");
