import "../base.scss";
import "./styles.scss";
import 'choices.js/public/assets/styles/choices.min.css'
import Choices from 'choices.js'

//Função do funcionamento das sanfonas
let financialStatements = () => {
  let financialStatementsArea = Array.from(document.querySelectorAll('.financial-statements'));
  
  if(financialStatementsArea){
    financialStatementsArea.forEach((financialStatementsAreaItem) => {
      let financialStatementsTabs = financialStatementsAreaItem.querySelectorAll('.financial-statements-item');
    
      financialStatementsTabs.forEach((financialStatementsTabsItem) => {
        let financialStatementsTabsItemHeader = financialStatementsTabsItem.querySelector('.financial-statements-item-top');

        financialStatementsTabsItemHeader?.addEventListener('click', (e) => {
          e.preventDefault();
          e.stopPropagation();

          if(financialStatementsTabsItem.classList.contains('active')){
            financialStatementsTabsItem.classList.remove('active');
          }

          else {
            financialStatementsTabsItem.classList.add('active');
          }
        });
      });
    });
  }
}

financialStatements();

export default financialStatements;

//Função para a lista de opções utilizando a ferramenta Choices
const YearSelect = function () {
  let elements = Array.from(document.querySelectorAll(".select"));

  if (elements) {
    elements.forEach(function (item) {
      let choices = () => new Choices(item, {
        searchEnabled: false,
        itemSelectText: "",
      });

      choices();
    });
  }
};

YearSelect();
