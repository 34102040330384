import "../base.scss";
import "./styles.scss";
import Choices from "choices.js";

const categoriaSelect = function () {
  let elements = Array.from(document.querySelectorAll(".select"));

  if (elements) {
    elements.forEach(function (item) {
      let choices = () => new Choices(item, {
        searchEnabled: false,
        itemSelectText: "",
      });

      choices();
    });
  }
};

categoriaSelect();