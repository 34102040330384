import "../base.scss";
import "./styles.scss";

//Função para fazer os itens aparecerem conforme a página vai descendo
window.addEventListener('scroll', revealItem);

function revealItem() {
    let itemList = Array.from(document.querySelectorAll('.history-item-list'));

    for(let i = 0; i < itemList.length; i++) {
        let windowHeight = window.innerHeight;
        let revealItem = itemList[i];
        let revealItemTop = revealItem.getBoundingClientRect().top;
        let revealPoint = 200;

        if(revealItemTop < windowHeight - revealPoint) {
            itemList[i].classList.add('active');
        }
        else {
            itemList[i].classList.remove('active');
        }
    }
}